
































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import MediaFee from '@/components/media/Fee.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  findAccount: (id: string) => ApiResource.Business.Account | undefined,
  listPayments: (id: string) => ApiResource.Business.Payment[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    MediaFee,
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null as Error | null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      findAccount: 'business/FindAccount',
      listFees: 'business/Fees/List',
    }),
    account(): ApiResource.Business.Account | undefined {
      return this.findAccount(this.business.id);
    },
    list(): ApiResource.Business.Payment[] {
      if (!this.account) return [];
      return this.listFees(this.account.id).sort(
        (a: ApiResource.Business.Fee, b: ApiResource.Business.Fee) => b.created_at - a.created_at,
      );
    },
    business(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
  },
  watch: {
    'business.id': {
      immediate: true,
      handler(to) {
        if (to) this.fetch();
      },
    },
  },
  methods: {
    handleError(e: Error) {
      this.error = e;
    },
    async fetch() {
      this.loading = true;

      await this.$store.dispatch('business/Fees/List', {
        BUSINESS_ID: this.business.id,
        query: {
          limit: 10,
          direction: 'desc',
          order: 'created_at',
        },
      }).catch(this.handleError);

      this.loading = false;
    },
  },
});
