




























































import Vue from 'vue';
import { ApiResource } from '@/types';
import ApplicationFeePdfDownloadButton from '@/components/dashboard/ApplicationFeePdfDownloadButton.vue';

export default Vue.extend({
  components: {
    ApplicationFeePdfDownloadButton,
  },
  props: {
    fee: {
      type: Object as () => ApiResource.Business.Fee,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  computed: {
    CURRENCY_DIVISOR(): number {
      let div = 100;

      switch (this.fee.currency) {
        case 'EUR':
        default:
          div = 100;
          break;
      }

      return div;
    },
    refunds(): ApiResource.Business.FeeRefund[] {
      if (!this.fee.refunds) return [];
      return this.fee.refunds.data;
    },
    voucher(): ApiResource.Business.InvoiceVoucher | undefined {
      if (!this.fee) return undefined;
      if (!this.fee.voucher) return undefined;

      return this.fee.voucher.data;
    },
  },
});
